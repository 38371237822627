<template>
<section id="InpatientSession">
    <inpatient :inpatientSessionId="inpatientSessionId" v-on:expansionUpdate="$emit('expansionUpdate')"/>
</section>
</template>

<script>
import { mapGetters } from "vuex";
import inpatient from "./inpatient.vue";

export default {
  name: "inpatient-index",
  components: {
    inpatient,
  },
  props: {
propInpatientSessionId: {
  type: Number,
  default: 0
}
  },
  data: () => ({
  }),
  watch: {
    inpatientSession(item) {
      this.inpatientObj = item;
    },
  },
  computed: {
    inpatientSessionId() {
      if (this.$props.propInpatientSessionId || this.$props.propInpatientSessionId  > 0 ) {
        return this.$props.propInpatientSessionId;
      } else {
        return parseInt(this.$route.params.inpatientSessionId , 0);
      }
    },
    patientId() {
      return parseInt(this.$route.params.id, 0);
    },
    ...mapGetters("patient", {
      patient: "patient",
    }),
  },
  mounted() {
     this.$store.dispatch("patient/get_patient", this.patientId);
  },
};
</script>

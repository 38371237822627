<template>
  <BaseModal
    :loading="loading"
    :dialog="createDialog"
    width="1080"
    v-on:cancel="$emit('cancel')"
  >
    <template v-slot:title>
      Create Patient Log
    </template>
    <template v-slot:body>
      <v-form ref="form" v-model="valid" lazy-validation>
        <v-row>
          <v-col sm="12">
            <v-select
              label="Monitor Type"
              v-model="patientLog.monitorId"
              :items="monitorTypes"
              :loading="loadingMonitorTypes"
              item-text="name"
              item-value="monitorId"
              outlined
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col sm="12">
            <v-select
              label="Category"
              v-model="patientLog.categoryTypes"
              :items="categories"
              :loading="loadingCategories"
              return-object
              item-text="description"
              item-value="value"
              multiple
              outlined
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col sm="12">
            <v-textarea
              :counter="150"
              v-model="patientLog.monitorLog"
              label="Monitor Log"
              required
              outlined
              append-icon="mdi-book"
              height="400px"
            ></v-textarea>
          </v-col>
        </v-row>
      </v-form>
    </template>
    <template v-slot:actions>
      <v-btn
        @click="submit"
        large
        color=""
        depressed
        class="v-btn--text primary--text"
        :disabled="!valid || loading"
        submit
        >submit</v-btn
      >
      <v-btn
        @click="clear"
        color=""
        depressed
        class="v-btn--text error--text"
        :disabled="loading"
        >clear</v-btn
      >
    </template>
  </BaseModal>
</template>

<script>
import services from "@/services";

export default {
  $_veeValidate: {
    validator: "new",
  },
  props: {
    createDialog: {
      type: Boolean,
      default: false,
    },
    patientId: {
      type: Number,
      default: 0,
    },
    categoryTypesProp:  {
      type: Array,
      default: null
    },
    monitorTypesProp: {
      type: Array,
      default: null
    }
  },
  name: "createLog",
  data: () => ({
    loading: false,
    loadingCategories: false,
    loadingMonitorTypes: false,
    show1: false,
    valid: true,
    patientLog: {
      patientId: 0,
      monitorId: 0,
      categoryTypes: 0,
      monitorLog: "",
    },
    monitorTypes: [],
    categories: [],
    dictionary: {
      attributes: {
        email: "E-mail Address",
        // custom attributes
      },
      custom: {
        // name: {
        //   required: () => "Name can not be empty",
        //   max: "The name field may not be greater than 10 characters",
        //   // custom messages
        // },
        // select: {
        //   required: () => "Select field is required",
        // },
      },
    },
  }),
  watch: {
    createDialog() {},
    categoryTypesProp(items) {
      if (items) {
        this.categories = items;
      }
    },
    monitorTypesProp(items) {
      if (items) {
        this.monitorTypes = items;
      }
    }
  },
  methods: {
    submit() {
      this.$validator.validateAll();
      this.loading = true;
      this.patientLog.patientId = this.$props.patientId;
      services.patientlogService
        .create(this.patientLog)
        .then(response => {
          if (response) {
            this.$store.commit(
              "snack/set_snack",
              "Patient log successfully created."
            );

            this.$emit("logWasCreated");
            return this.clear();
          } else {
            return this.$store.commit(
              "snack/set_snack",
              "Failed to create patient log. Please try again."
            );
          }
        })
        // eslint-disable-next-line no-unused-vars
        .catch(() => {
          return this.$store.commit(
            "snack/set_snack",
            "Failed to create patient log. Please try again."
          );
        })
        .finally(() => (this.loading = false));
    },
    async getCategories() {
      this.loadingCategories = true;

      await services.patientLogService
        .getCategories()
        .then((response) => {
          this.categories = response;
        })
        .finally(() => (this.loadingCategories = false));
    },
    async getMonitorTypes() {
      this.loadingMonitorTypes = true;

      await services.patientLogService
        .getMonitorTypes()
        .then((response) => {
          this.monitorTypes = response;
        })
        .finally(() => (this.loadingMonitorTypes = false));
    },
    clear() {
      this.patientLog = {};
      this.patientLog.patientId = 0;
      this.patientLog.monitorTypeId = 0;
      this.patientLog.categoryId = 0;
      this.patientLog.monitorLog = "";
      this.$validator.reset();
    },
  },
  async mounted() {
      if (!this.$props.monitorTypesProp) {
      await this.getMonitorTypes();
    }

    if (!this.$props.categoryTypesProp) {
      await this.getCategories();
    }
    this.$validator.localize("en", this.dictionary);
    this.clear();
  },
};
</script>

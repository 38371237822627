<template>
  <BaseModal
    :loading="loading"
    :dialog="createDialog"
    v-on:cancel="$emit('cancel')"
    width="1080"
  >
    <template v-slot:title>
      Create Outpatient Session
    </template>
    <template v-slot:body>
      <v-form ref="form" v-model="valid" lazy-validation>
        <v-row>
          <v-col lg="4" xs="12">
            <BaseDatePicker
              label="Setup Date"
              :readonly="loading"
              :date="outpatientObj.setupDate"
              @change="outpatientObj.setupDate = $event"
            />
          </v-col>
          <v-col lg="4" xs="12">
            <BaseDatePicker
              label="Disconnect Date"
              :readonly="loading"
              :date="new Date().toISOString().substr(0, 10)"
              @change="outpatientObj.disconnectDate = $event"
            />
          </v-col>
          <v-col lg="4" xs="12">
            <v-text-field
              :counter="100"
              v-model="outpatientObj.testLength"
              label="Test Length"
              required
              outlined
              append-icon="mdi-timer"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col lg="4" xs="12">
            <v-select
              v-model="outpatientObj.sessionStatusId"
              :loading="statusLoader"
              :items="statusList"
              label="Session Status"
              item-text="description"
              item-value="lookSessionStatusId"
              outlined
            >
            </v-select>
          </v-col>
          <v-col lg="4" xs="12">
            <v-text-field
              label="Unit Number"
              required
              outlined
              v-model="outpatientObj.unitId"
              append-icon="mdi-ballot"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col lg="4" xs="12">
            <v-text-field
              :counter="100"
              label="Ordering Physician"
              v-model="outpatientObj.orderingPhysician"
              required
              outlined
              append-icon="mdi-doctor"
            ></v-text-field>
          </v-col>
          <v-col lg="4" xs="12">
            <v-text-field
              :counter="100"
              label="Reading Physician"
              v-model="outpatientObj.readingPhysician"
              required
              outlined
              append-icon="mdi-doctor"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col sm="12">
            <v-textarea
              :counter="150"
              v-model="outpatientObj.details"
              label="Details"
              required
              outlined
              append-icon="mdi-book"
              height="400px"
            ></v-textarea>
          </v-col>
        </v-row>
      </v-form>
    </template>
    <template v-slot:actions>
      <v-btn
        @click="submit"
        large
        color=""
        depressed
        class="v-btn--text primary--text"
        :disabled="!valid || loading"
        submit
        >submit</v-btn
      >
      <v-btn
        @click="clear"
        color=""
        depressed
        class="v-btn--text error--text"
        :disabled="loading"
        >clear</v-btn
      >
    </template>
  </BaseModal>
</template>

<script>
import services from "@/services";

export default {
  $_veeValidate: {
    validator: "new",
  },
  props: {
    createDialog: {
      type: Boolean,
      default: false,
    },
    patientId: {
      type: Number,
      default: 0,
    },
  },
  name: "session-add",
  data: () => ({
    currentDate: "",
    loading: false,
    show1: false,
    valid: false,
    statusLoader: false,
    phoneMask: [
      "(",
      /\d/,
      /\d/,
      /\d/,
      ") ",
      /\d/,
      /\d/,
      /\d/,
      "-",
      /\d/,
      /\d/,
      /\d/,
      /\d/,
    ],
    outpatientObj: {
      setupDate: "",
      patientId: 0,
      disconnectDate: "",
      testLength: "",
      sessionStatusId: 1,
      unitId: "",
      fullOrShort: "full",
      continuous: true,
      details: "",
      physician: "n/a",
      orderingPhysician: "",
      readingPhysician: ""
    },
    statusList: [],
    dictionary: {
      attributes: {
        email: "E-mail Address",
        // custom attributes
      },
      custom: {
        // name: {
        //   required: () => "Name can not be empty",
        //   max: "The name field may not be greater than 10 characters",
        //   // custom messages
        // },
        // select: {
        //   required: () => "Select field is required",
        // },
      },
    },
  }),
  computed: {
    availableRoles() {
      return this.$store.state.admin.availableRoles;
    },
  },
  watch: {
    createDialog() {
      this.outpatientObj.setupDate = new Date().toISOString();
      this.outpatientObj.disconnectDate = new Date()
        .toISOString()
        .substr(0, 10);
    },
  },
  methods: {
    submit() {
      this.$validator.validateAll();
      this.loading = true;
          this.outpatientObj.patientId = this.$props.patientId;
     

      services.outpatientService
        .create(this.outpatientObj)
        .then((response) => {
          if (response) {
            this.$store.commit(
              "snack/set_snack",
              "Outpatient session was successfully created."
            );

            this.$emit("sessionWasCreated");
            return this.clear();
          } else {
            return this.$store.commit(
              "snack/set_snack",
              "Failed to create outpatient session. Please try again."
            );
          }
        })
        // eslint-disable-next-line no-unused-vars
        .catch((error) => {
          return this.$store.commit(
            "snack/set_snack",
            "Failed to create outpatient session. Please try again."
          );
        })
        .finally(() => (this.loading = false));
    },
    async getSessionStatuses() {
      this.statusLoader = true;

      return await services.patientService
        .getSessionStatuses()
        .then((response) => {
          this.statusList = response;
        })
        .finally(() => (this.statusLoader = false));
    },
    clear() {
      this.outpatientObj.setupDate = new Date().toISOString();
      this.outpatientObj.disconnectDate = new Date()
        .toISOString()
        .substr(0, 10);
      this.outpatientObj.testLength = 0;
      this.outpatientObj.sessionStatusId = 1;
      this.outpatientObj.unitId = "";
      this.outpatientObj.fullOrShort = "full";
      this.outpatientObj.continuous = true;
      this.outpatientObj.details = "";
      this.outpatientObj.physician = "n/a";
      this.outpatientObj.orderingPhysician = "";
      this.outpatientObj.readingPhysician = "";
      this.$validator.reset();
    },
  },
  mounted: function() {
    this.$validator.localize("en", this.dictionary);
    this.getSessionStatuses();
    this.clear();
    this.currentDate = new Date().toISOString();
  },
};
</script>

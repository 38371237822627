<template>
    <section id="outpatient">
        <v-toolbar flat style="border-bottom: 1px solid #c0c0c0">
            <h2>Patient Logs</h2>
            <v-spacer />
            <v-btn color="primary" class="pr-3 mr-3"
                   @click="downloadPatientLogs()"
                   v-if="isAdminUser"
                   target="_blank">
                Export
            </v-btn>
            <v-btn color="primary" @click="createDialog = true" :disabled="isReadOnly">
                Create Patient Log
            </v-btn>
        </v-toolbar>
        <v-row class="pa-3">
            <v-spacer />
            <v-col>
                <v-text-field v-model="searchText"
                              label="Search"
                              single-line
                              append-icon="mdi-magnify"
                              outlined
                              dense
                              hide-details
                              full-width></v-text-field>
            </v-col>
            <v-col>
                <v-select v-model="filteredMonitorIds"
                          :multiple="true"
                          :loading="monitorLoader"
                          :items="monitorTypes"
                          label="Patient Status"
                          color="secondary"
                          item-text="name"
                          item-value="monitorId"
                          hide-details
                          outlined
                          clearable
                          single-line
                          full-width
                          dense>
                    <template v-slot:selection="{ attrs, item, index, selected }">
                        <span v-bind="attrs" :input-value="selected" v-if="index < 1">
                            <span>{{ item.name }}</span>
                        </span>
                        <span v-if="index == 1" class="grey--text caption">(+{{ monitorTypes.length - 1 }} others)</span>
                    </template>
                </v-select>
            </v-col>
        </v-row>
        <v-data-table :headers="headers"
                      :items="patientLogs"
                      :search="searchText"
                      :options.sync="options"
                      :server-items-length="totalCount"
                      :loading="loading"
                      item-key="patientLogId"
                      dense
                      calculate-widths
                      @click:row="rowClick">
            <template v-slot:item.monitorType="props">
                {{ props.item.monitorType.name }}
            </template>
            <template v-slot:item.createDate="props">
                {{ formatDate(props.item.createDate) }}
            </template>
            <template v-slot:item.categoryTypes="{ item }">
                <v-list dense>
                    <v-list-item v-for="(categoryType, index) in item.categoryTypes"
                                 :key="index">
                        <v-list-item-content>
                            {{
              categoryType.description
                            }}
                        </v-list-item-content>
                    </v-list-item>
                </v-list>
            </template>
            <template v-slot:item.monitorLog="{ item }">
                <v-textarea readonly v-model="item.monitorLog" outlined class="pt-5">
                </v-textarea>
            </template>
            <template v-slot:item.actions="{ item }">
                <v-tooltip top>
                    <template v-slot:activator="{ on, attrs }">
                        <v-icon class="mr-2"
                                v-bind="attrs"
                                v-on="on"
                                :disabled="loading"
                                @click="editLog(item)">
                            mdi-pencil
                        </v-icon>
                    </template>
                    <span>Edit Log</span>
                </v-tooltip>
                <v-tooltip top>
                    <template v-slot:activator="{ on, attrs }">
                        <v-icon class="mr-2" v-bind="attrs" v-on="on">
                            mdi-delete
                        </v-icon>
                    </template>
                    <span>View Full Details</span>
                </v-tooltip>
            </template>
        </v-data-table>


        <createLog :createDialog="createDialog"
                   :patientId="patientId"                   
                   :categoryTypesProp="categoryTypes"
                   :monitorTypesProp="monitorTypes"
                   v-on:cancel="createDialog = false"
                   v-on:logWasCreated="
        expanded = [];
        getPatientLogs();
        createDialog = false;
      " />

        <updateLog :updateDialog="updateDialog"
                   :patientId="patientId"
                   :selectedCategoryTypesProp="selectedCategories"
                   :categoryTypesProp="categoryTypes"
                   :monitorTypeId="selectedMonitorId"
                   :monitorTypesProp="monitorTypes"
                   :monitorLogProp="selectedMonitorLog"
                   :patientLogIdProp="selectedPatientLogId"
                   v-on:cancel="updateDialog = false"
                   v-on:logWasUpdated="
        expanded = [];
        getPatientLogs();
        updateDialog = false;
      " />
    </section>
</template>

<script>
import createLog from "./createLog";
import updateLog from "./updateLog";
import moment from "moment";
import services from "@/services";
import { ToSearchPageRequest } from "@/models";
export default {
  name: "patientLogs",
  components: {
      createLog,
      updateLog,
  },
  props: {
    patientId: {
      type: Number,
      default: 0,
    },
  },
  data: () => ({
    createDialog: false,
    updateDialog: false,
    loading: false,
    timer: null,
    selected: null,
    patientLogs: [],
    monitorTypes: [],
      categoryTypes: [],
    selectedMonitorLog: "",
    selectedCategories: [],
    filteredMonitorIds: [],
    selectedMonitorId: 0,
    selectedPatientLogId: 0,
    monitorLoader: false,
    categoryLoader: false,
    options: {
      groupBy: [],
      groupDesc: [],
      itemsPerPage: 5000,
      multiSort: false,
      mustSort: false,
      page: 1,
      sortBy: ["createDate"],
      sortDesc: [true],
    },
    defaultOptions: {
      groupBy: [],
      groupDesc: [],
      itemsPerPage: 25,
      multiSort: false,
      mustSort: false,
      page: 1,
      sortBy: ["createDate"],
      sortDesc: [true],
    },
    searchText: "",
    totalCount: 0,
    items: [],
    headers: [
      {
        text: "Timestamp",
        align: "start",
        sortable: true,
        value: "createDate",
        width: 200,
      },
      {
        text: "Monitor Type",
        align: "start",
        value: "monitorType",
        width: 100,
      },
      {
        text: "Category(s)",
        align: "start",
        value: "categoryTypes",
        width: 150,
      },
      {
        text: "First Name",
        value: "firstName",
        align: "start",
        sortable: true,
        width: 150,
      },
      {
        text: "Last Name",
        value: "lastName",
        align: "start",
        sortable: true,
        width: 150,
      },
      {
        text: "Log",
        value: "monitorLog",
        align: "top",
      },
    ],
    page: {
      title: "Patients",
      icon: "mdi-patient",
    },
  }),
  watch: {
    items(item) {
      this.patientLogs = item;
    },
    patientLogs(item) {
      var that = this;
      setTimeout(function() {
        that.expanded.push(item[0]);
      }, 300);
    },
    options: {
      async handler() {
        this.expanded = [];
        //await this.getPatientLogs();
      },
      deep: true,
    },
    searchText: {
      async handler() {
        if (this.timer) {
          clearTimeout(this.timer);
          this.timer = null;
        }
        this.timer = setTimeout(async () => {
          await this.getPatientLogs();
        }, 1000);
      },
      deep: true,
    },
  },
  computed: {
    isAdminUser() {
      return this.$store.state.user.isAdmin;
      },
      isReadOnly() {          
          return this.$store.state.user.isReadOnly;
    }
  },
  methods: {
    editLog(item) {
      this.selectedPatientLog = item;
    },
    formatDate(date) {
      return moment.utc(date).tz('America/Chicago').format("MM/DD/YYYY HH:mm");
    },
    rowClick: function (item) {
        if (this.$store.state.user.isAdmin) {
            this.selectedMonitorProp = "";
            this.selectedMonitorId = item.monitorId;
            this.selectedCategories = item.categoryTypes;
            this.selectedMonitorLog = item.monitorLog;
            this.selectedPatientLogId = item.patientLogId;
            this.updateDialog = true;
            this.selected = item;
        }
    },
    async getCategories() {
      this.categoryLoader = true;

      await services.patientlogService
        .getCategoryTypesList()
        .then((response) => {
          this.categoryTypes = response;
        })
        .finally(() => (this.categoryLoader = false));
    },
    async getMonitors() {
      this.monitorLoader = true;

      await services.patientlogService
        .getMonitorTypes()
          .then((response) => {
          this.monitorTypes = response;
          this.filteredMonitorIds = response.map((x) => x.monitorId);
        })
        .finally(() => (this.monitorLoader = false));
    },
    async getPatientLogs() {
      this.loading = true;
      let that = this;

      var request = ToSearchPageRequest(this.searchText, this.options);
      request.patientId = that.$props.patientId;
      request.monitorIds = that.filteredMonitorIds;
      await services.patientlogService
        .search(request)
        .then((data) => {
          this.items = data.pagedResults.results;
          this.totalCount = data.pagedResults.totalCount;
        })
        .finally(() => (this.loading = false));
    },
    async downloadPatientLogs() {
      this.loading = true;
        await services.adminService
            .downloadPatientLogs(this.$props.patientId)
            .then(() => {
            })
            .finally(() => (this.loading = false));
    },
  },
  async mounted() {
    await this.getMonitors();
    await this.getCategories();
    await this.getPatientLogs();

  },
};
</script>

<template>
  <section id="patient">
    <v-row>
      <v-col cols="4" xs="12">
        <v-text-field
          v-model="patientObj.firstName"
          :disabled="isReadOnly"
          label="First Name"
          :readonly="loading"
          color="secondary"
          outlined
        ></v-text-field>
      </v-col>
      <v-col cols="4" xs="12">
        <v-text-field
          v-model="patientObj.middleName"
         :disabled="isReadOnly"
          label="Middle Name"
          :readonly="loading"
          color="secondary"
          outlined
        ></v-text-field>
      </v-col>
      <v-col cols="4" xs="12">
        <v-text-field
          v-model="patientObj.lastName"
         :disabled="isReadOnly"
          label="Last Name"
          :readonly="loading"
          color="secondary"
          outlined
        ></v-text-field>
      </v-col>
      <v-col cols="4" xs="12">
        <BaseBirthDatePicker 
        :birthDate="patientObj.dateOfBirth"             
        :readonly="isReadOnly"
        @change="patientObj.dateOfBirth = $event"
        />
      </v-col>
      <v-col cols="4" xs="12">
        <v-combobox
          v-model="genderSelection"
          :disabled="isReadOnly"
          :items="genderItems"
          label="Gender"
          :readonly="loading"
          persistent-hint
          outlined
        ></v-combobox>
      </v-col>
    </v-row>
    <v-row> </v-row>
    <v-divider class="pb-4" />
    <v-row>
      <v-col cols="12">
        <h3>Contact</h3>
      </v-col>
      <v-col cols="6" xs="12">
        <v-text-field
          v-model="patientObj.homePhone"
          label="Home Phone"
          v-mask="phoneMask"
                      :disabled="isReadOnly"
          color="secondary"
          :readonly="loading"
          outlined
        ></v-text-field>
      </v-col>
      <v-col cols="6" xs="12">
        <v-text-field
          v-model="patientObj.mobilePhone"
          label="Mobile Phone"
          v-mask="phoneMask"
                      :disabled="isReadOnly"
          color="secondary"
          outlined
          :readonly="loading"
        ></v-text-field>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="6" xs="12">
        <v-text-field
          v-model="patientObj.emergencyContactName"
                      :disabled="isReadOnly"
          label="Emergency Contact Name"
          color="secondary"
          outlined
          :readonly="loading"
        ></v-text-field>
      </v-col>
      <v-col cols="6" xs="12">
        <v-text-field
          v-model="patientObj.emergencyContactPhone"
                      :disabled="isReadOnly"
          label="Emergency Contact Phone"
          v-mask="phoneMask"
          color="secondary"
          outlined
        ></v-text-field>
      </v-col>
    </v-row>
    <v-divider class="pb-4" />
    <v-row>
      <v-col cols="12">
        <h3>Home Address</h3>
      </v-col>
      <v-col cols="6" xs="12">
        <v-text-field
          v-model="patientObj.address1"
                      :disabled="isReadOnly"
          label="Address One"
          color="secondary"
          :readonly="loading"
          outlined
        ></v-text-field>
      </v-col>
      <v-col cols="6" xs="12">
        <v-text-field
          v-model="patientObj.address2"
                      :disabled="isReadOnly"
          label="Address Two"
          color="secondary"
          outlined
          :readonly="loading"
        ></v-text-field>
      </v-col>
      <v-col cols="4" xs="12">
        <v-text-field
          v-model="patientObj.city"
                      :disabled="isReadOnly"
          label="City"
          color="secondary"
          outlined
          :readonly="loading"
        ></v-text-field>
      </v-col>
      <v-col cols="4" xs="12">
        <v-text-field
          v-model="patientObj.stateCd"
                      :disabled="isReadOnly"
          label="State"
          :readonly="loading"
          color="secondary"
          outlined
        ></v-text-field>
      </v-col>
      <v-col cols="4" xs="12">
        <v-text-field
          v-model="patientObj.zip"
                      :disabled="isReadOnly"
          label="Zip"
          color="secondary"
          :readonly="loading"
          outlined
        ></v-text-field>
      </v-col>
    </v-row>
    <v-divider class="pb-4" />
    <v-row>
      <v-col xs="12">
        <v-textarea
          v-model="patientObj.notes"
                      :disabled="isReadOnly"
          color="Secondary"
          outlined
          :readonly="loading"
          label="Notes"
          height="400px"
        />
      </v-col>
    </v-row>
    <v-row>
      <v-col sm="12" v-if="!newPatient">
        <v-card-actions >
          <v-spacer />
          <v-btn
            @click="update"                      
            large
            color
            depressed
            class="v-btn--text primary--text"
            :disabled="isReadOnly"
            :loading="loading"
            submit
            >Submit</v-btn
          >
          <v-btn
            @click="clear"
            color
            depressed
            class="v-btn--text error--text"
            :disabled="isReadOnly"
            >Reset</v-btn
          >
        </v-card-actions>
      </v-col>
    </v-row>
  </section>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "patientForm",
  props: {
    loading: {
      type: Boolean,
      default: false
    },
    newPatient: {
      type: Boolean,
      default: false
    },
    patientId: {
    type: Number,
    default: 0,
  },
  },
  data: () => ({
    menu1: false,
    patientObj: {},
    page: {
      title: "profile",
      icon: "mdi-patient",
    },
    breadcrumbs: [
      {
        text: "profile",
        disabled: true,
      },
    ],
    genderSelection: [],
    genderItems: ["Male", "Female", "Other"],
    phoneMask: [
      "(",
      /\d/,
      /\d/,
      /\d/,
      ") ",
      /\d/,
      /\d/,
      /\d/,
      "-",
      /\d/,
      /\d/,
      /\d/,
      /\d/,
    ],
  }),
  watch: {
    patient(update) {
      this.patientObj = update;
      this.patientObj.dateOfBirth = update.dateOfBirth;
      this.genderSelection.push(update.gender);
    },
    genderSelection(selection) {
      this.patientObj.gender = selection[0];
    },
  },
  computed: {
    ...mapGetters("patient", {
      patient: "patient",
    }),
      isReadOnly() {
          return this.$store.state.user.isTech || this.$store.state.user.isReadOnly;
      },      
        },
        
  methods: {
    parseDate(date) {
      if (!date) return null;

      const [month, day, year] = date.split("/");
      return `${year}-${month.padStart(2, "0")}-${day.padStart(2, "0")}`;
      },

    update() {
     this.$emit('patientUpdated', this.patientObj)
    },
    updateDateOfBirth(date) {
      this.patientObj.dateOfBirth = date;
    },
    async clear() {
      await this.$store.dispatch("patient/get_patient", this.$props.patientId);
    },
  },
  async mounted() {
      await this.$store.dispatch("patient/get_patient", this.$props.patientId);

      //console.log("ReadOnly")
      console.log(this.isReadOnly);
  },
};
</script>

<template>
  <BaseModal
    :loading="loading"
    :dialog="updateDialog"
     v-if="renderComponent"
    v-on:cancel="$emit('cancel')"
  >
    <template v-slot:title>
      Update Session Detail
    </template>
    <template v-slot:body>
      <v-form ref="form" v-model="valid" lazy-validation>
        <v-row>
          <v-col sm="12">
            <v-select
              outlined
              label="Session Shift"
              :items="sessionShifts"
              v-model="selectedSessionShift"
              return-object
              item-text="ShiftDisplayValue"
              hide-details
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col sm="12">
            <BaseDatePicker
              label="Start Date"
              :actualDate="getMoment(setupDate)"
              @change="request.startTime = $event"
              :min="setupDate"
              :max="disconnectDate"
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col sm="12">
            <BaseDatePicker
              label="End Date"
              :actualDate="getMoment(setupDate)"
              @change="request.endTime = $event"
              :min="setupDate"
              :max="disconnectDate"
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col sm="12">
            <v-autocomplete
              :items="availableTechnicians"
              v-model="selectedUserId"
              item-text="fullName"
              item-value="id"
              outlined
              append-icon="mdi-account"
              label="Technician"
              hide-details
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col sm="12">
            <v-select
              :items="techTypes"
              :loading="techTypeLoader"
              v-model="selectedTechType"
              item-text="description"
              item-value="lookTechnicianTypeId"
              label="Technician Role"
              outlined
              hide-details
            >
            </v-select>
          </v-col>
        </v-row>
      </v-form>
    </template>
    <template v-slot:actions>
      <v-btn
        @click="submit"
        large
        color=""
        depressed
        class="v-btn--text primary--text"
        :disabled="!valid || loading"
        submit
        >submit</v-btn
      >
      <v-btn
        @click="clear"
        color=""
        depressed
        class="v-btn--text error--text"
        :disabled="loading"
        >clear</v-btn
      >
    </template>
  </BaseModal>
</template>
<script>
import services from "@/services";
import moment from "moment";

export default {
  name: "detail-update",
  props: {
    sessionDetail: {
      type: Object,
      default: null,
    },
    updateDialog: {
      type: Boolean,
      default: false,
    },
     setupDate: {
      type: String,
      default: "",
    },
    disconnectDate: {
      type: String,
      default: "",
    },
  },
  data: () => {
    return {
      loading: false,
      techLoading: false,
      valid: false,
      request: {},
      availableTechnicians: [],
      techTypeLoader: false,
      techTypes: [],
      selectedTechType: null,
      selectedUserId: null,
      renderComponent: true,
      selectedSessionShift: null,
      sessionShifts: [
        {
          ShiftDisplayValue: "11:30p-3:30a",
          ShiftStartTime: "23:30",
          ShiftEndTime: "3:30",
        },
        {
          ShiftDisplayValue: "3:30a-7:30a",
          ShiftStartTime: "3:30",
          ShiftEndTime: "7:30",
        },
        {
          ShiftDisplayValue: "7:30a-11:30a",
          ShiftStartTime: "7:30",
          ShiftEndTime: "11:30",
        },
        {
          ShiftDisplayValue: "11:30a-3:30p",
          ShiftStartTime: "11:30",
          ShiftEndTime: "15:30",
        },
        {
          ShiftDisplayValue: "3:30p-7:30p",
          ShiftStartTime: "15:30",
          ShiftEndTime: "19:30",
        },
        {
          ShiftDisplayValue: "7:30p-11:30p",
          ShiftStartTime: "19:30",
          ShiftEndTime: "23:30",
        },
      ]
    };
  },
  watch: {
    sessionDetail(update) {
      this.syncModel(update);
    },
  },
  methods: {
    submit() {
      if (!this.selectedSessionShift) {
        this.$store.commit(
              "snack/set_snack",
              "Please select a session shift before hitting submit."
            );
            return;
      }
      this.$validator.validateAll();
      this.loading = true;

      var payload = this.$props.sessionDetail;
      payload.sessionDetailId = this.request.sessionDetailId;
      payload.outpatientSessionId = this.request.outpatientSessionId;
      payload.startTime = this.request.startTime;
      payload.endTime = this.request.endTime;
      payload.technician = this.request.technician;
      payload.completed = this.request.completed;
      payload.lookTechnicianTypeId = this.selectedTechType;
      payload.user = this.selectedUserId;

       var requestObj = payload;
      var formattedStartTime =
        this.request.startTime.toString() +
        " " +
        this.selectedSessionShift.ShiftStartTime;
      var formattedEndTime =
        this.request.endTime.toString() +
        " " +
        this.selectedSessionShift.ShiftEndTime;
         requestObj.startTime = this.$moment(formattedStartTime)._i
        requestObj.endTime = this.$moment(formattedEndTime)._i

      services.sessionDetailService
        .update(payload)
        .then((response) => {
          if (response) {
            this.$store.commit(
              "snack/set_snack",
              "Session detail was successfully updated."
            );

            this.$emit("detailWasUpdated");
            return this.clear();
          } else {
            return this.$store.commit(
              "snack/set_snack",
              "Failed to save session detail. Please try again."
            );
          }
        })
        // eslint-disable-next-line no-unused-vars
        .catch((error) => {
          return this.$store.commit(
            "snack/set_snack",
            "Failed to save session detail. Please try again."
          );
        })
        .finally(() => (this.loading = false));
    },
    async getAvailableTechnicians() {
      this.techLoading = true;
      await services.sessionDetailService
        .getAvailableTechnicians(this.$props.sessionDetail.outpatientSessionId)
        .then((data) => {
          this.availableTechnicians = data;
          this.availableTechnicians.push(this.request.technician);
        })
        .finally(() => (this.techLoading = false));
    },
    async getTechnicianTypes() {
      this.techTypeLoader = true;

      await services.sessionDetailService
        .getTechnicianTypes()
        .then((response) => {
          this.techTypes = response;
        })
        .finally(() => (this.techTypeLoader = false));
    },
    getTime(date) {
      return moment(date).format("H:mm");
    },
    getMoment(date) {
      return this.$moment(date)._d;
    },
    clear() {
      this.syncModel(this.$props.sessionDetail);
      // hack to reset
      this.renderComponent = false;
      this.renderComponent = true;

      this.$validator.reset();
    },
    syncModel(model) {
      this.request.sessionDetailId = model.sessionDetailId;
      this.request.outpatientSessionId = model.outpatientSessionId;
      this.request.technician = model.technician;
      this.request.completed = model.completed;
      this.request.technicianType = model.technicianType;
      this.selectedTechType = model.technicianType.lookTechnicianTypeId;
      this.selectedUserId = model.technician.id;

      // Let the fuckery begin.
      var startTime = this.getTime(model.startTime);
      
      var matches = this.sessionShifts.filter(ss=> ss.ShiftStartTime == startTime.toString())

      if (matches) {
          this.selectedSessionShift = matches[0]
        }
      
      this.request.startTime = new Date(model.startTime).toISOString().substring(0,10);
      this.request.endTime = new Date(model.endTime).toISOString().substring(0,10);
    },
    getAlertColor(item) {
      if (!item) return "secondary";

      var status = item.description.replace("Tech", "");

      switch (status) {
        case "Pickup":
          return "error";
        case "Maintenance":
          return "info";
        case "Setup":
          return "yellow";
        case "Reapplication":
          return "accent";
        case "Upload":
          return "success";
        default:
          return "secondary";
      }
    },
  },
  async mounted() {
    this.syncModel(this.$props.sessionDetail);
    await this.getAvailableTechnicians();
    await this.getTechnicianTypes();
  },
};
</script>

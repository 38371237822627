<template>
  <BaseModal
    :loading="loading"
    :dialog="createDialog"
    v-on:cancel="$emit('cancel')"
  >
    <template v-slot:title>
      Create Session Detail
    </template>
    <template v-slot:body>
      <v-form ref="form" v-model="valid" lazy-validation>
        <v-row>
          <v-col sm="12">
            <v-select
              outlined
              label="Session Shift"
              :items="sessionShifts"
              v-model="selectedSessionShift"
              return-object
              item-text="ShiftDisplayValue"
              hide-details
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col sm="12">
            <BaseDatePicker
              label="Start Date"
              :actualDate="getMoment(setupDate)"
              @change="request.startTime = $event"
              :min="setupDate"
              :max="disconnectDate"
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col sm="12">
            <BaseDatePicker
              label="End Date"
              :actualDate="getMoment(setupDate)"
              @change="request.endTime = $event"
              :min="setupDate"
              :max="disconnectDate"
            />
          </v-col>
        </v-row>

        <v-row>
          <v-col sm="12">
            <v-autocomplete
              :items="availableTechnicians"
              v-model="request.user"
              item-text="fullName"
              item-value="id"
              outlined
              append-icon="mdi-account"
              label="Technician"
              hide-details
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col sm="12">
            <v-select
              :items="techTypes"
              :loading="techTypeLoader"
              v-model="request.lookTechnicianTypeId"
              item-text="description"
              item-value="lookTechnicianTypeId"
              label="Technician Role"
              outlined
              hide-details
            >
            </v-select>
          </v-col>
        </v-row>
      </v-form>
    </template>
    <template v-slot:actions>
      <v-btn
        @click="submit"
        large
        color=""
        depressed
        class="v-btn--text primary--text"
        :disabled="!valid || loading"
        submit
        >submit</v-btn
      >
      <v-btn
        @click="clear"
        color=""
        depressed
        class="v-btn--text error--text"
        :disabled="loading"
        >Reset</v-btn
      >
    </template>
  </BaseModal>
</template>
<script>
import services from "@/services";
import moment from "moment";

export default {
  name: "detail-create",
  props: {
    outpatientSessionId: {
      type: Number,
      default: 0,
    },
    createDialog: {
      type: Boolean,
      default: false,
    },
    setupDate: {
      type: String,
      default: "",
    },
    disconnectDate: {
      type: String,
      default: "",
    },
  },
  data: () => {
    return {
      loading: false,
      valid: false,
      techLoading: false,
      request: {
        outpatientSessionId: 0,
        startTime: new Date(),
        endTime: new Date(),
        lookTechnicianTypeId: 1,
        user: "",
      },
      availableTechnicians: [],
      techTypeLoader: false,
      techTypes: [],
      renderComponent: true,
      selectedSessionShift: null,
      sessionShifts: [
        {
          ShiftDisplayValue: "11:30p-3:30a",
          ShiftStartTime: "23:30",
          ShiftEndTime: "3:30",
        },
        {
          ShiftDisplayValue: "3:30a-7:30a",
          ShiftStartTime: "3:30",
          ShiftEndTime: "7:30",
        },
        {
          ShiftDisplayValue: "7:30a-11:30a",
          ShiftStartTime: "7:30",
          ShiftEndTime: "11:30",
        },
        {
          ShiftDisplayValue: "11:30a-3:30p",
          ShiftStartTime: "11:30",
          ShiftEndTime: "15:30",
        },
        {
          ShiftDisplayValue: "3:30p-7:30p",
          ShiftStartTime: "15:30",
          ShiftEndTime: "19:30",
        },
        {
          ShiftDisplayValue: "7:30p-11:30p",
          ShiftStartTime: "19:30",
          ShiftEndTime: "23:30",
        },
      ],
    };
  },
  watch: {
    outpatientSessionId(id) {
      this.syncModel(id);
    },
  },
  methods: {
    async submit() {
      if (!this.selectedSessionShift) {
        this.$store.commit(
          "snack/set_snack",
          "Please select a session shift before hitting submit."
        );
        return;
      }
      this.$validator.validateAll();
      this.loading = true;
      this.request.outpatientSessionId = this.$props.outpatientSessionId;

      var requestObj = this.request;
      var formattedStartTime =
        this.request.startTime.toString() +
        " " +
        this.selectedSessionShift.ShiftStartTime;
      var formattedEndTime =
        this.request.endTime.toString() +
        " " +
        this.selectedSessionShift.ShiftEndTime;
         requestObj.startTime = this.$moment(formattedStartTime)._i
        requestObj.endTime = this.$moment(formattedEndTime)._i

      await services.sessionDetailService
        .create(requestObj)
        .then(response => {
          if (response) {
            this.$store.commit(
              "snack/set_snack",
              "Session detail was successfully added."
            );

            this.$emit("detailWasCreated", this.request);
            return this.clear();
          } else {
            return this.$store.commit(
              "snack/set_snack",
              "Failed to save session detail. Please try again."
            );
          }
        })
        // eslint-disable-next-line no-unused-vars
        .catch((error) => {
          return this.$store.commit(
            "snack/set_snack",
            "Failed to save session detail. Please try again."
          );
        })
        .finally(() => (this.loading = false));
    },
    async getAvailableTechnicians() {
      this.techLoading = true;
      await services.sessionDetailService
        .getAvailableTechnicians(this.$props.outpatientSessionId)
        .then((data) => {
          this.availableTechnicians = data;
          this.request.user = data[0].id;
        })
        .finally(() => (this.techLoading = false));
    },
    async getTechnicianTypes() {
      this.techTypeLoader = true;

      await services.sessionDetailService
        .getTechnicianTypes()
        .then((response) => {
          this.techTypes = response;
        })
        .finally(() => (this.techTypeLoader = false));
    },
    getTime(date) {
      return moment(date).format("hh:mm:ss a");
    },
    getMoment(date) {
      return new Date(date);
    },
    clear() {
      this.$emit("resetCreate");
      this.syncModel(this.$props.sessionDetail);
      this.request.startTime = this.$props.setupDate;
      this.request.endTime = this.$props.setupDate;
      this.request.lookTechnicianTypeId = 1;
      this.request.user = this.availableTechnicians[0].id;
      this.$validator.reset();
    },
    syncModel(outpatientSessionId) {
      this.request.outpatientSessionId = outpatientSessionId;

      this.request.startTime = moment(this.$props.setupDate).format(
        "YYYY-MM-DD"
      );
      this.request.endTime = moment(this.$props.setupDate).format("YYYY-MM-DD");
    },
    getAlertColor(item) {
      if (!item) return "secondary";

      var status = item.description.replace("Tech", "");

      switch (status) {
        case "Pickup":
          return "error";
        case "Maintenance":
          return "info";
        case "Setup":
          return "yellow";
        case "Reapplication":
          return "accent";
        case "Upload":
          return "success";
        default:
          return "secondary";
      }
    },
  },
  async mounted() {
    this.syncModel(this.$props.outpatientSessionId);
    await this.getAvailableTechnicians();
    await this.getTechnicianTypes();
  },
};
</script>

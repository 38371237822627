<template>
  <BaseModal
    :loading="loading"
    :dialog="createDialog"
    v-on:cancel="$emit('cancel')"
    width="1080"
  >
    <template v-slot:title>
      Create inpatient Session
    </template>
    <template v-slot:body>
      <v-form ref="form" v-model="valid" lazy-validation>
        <v-text-field
          :counter="50"
          label="Hospital"
          v-model="inpatientObj.hospital"
          required
          outlined
        ></v-text-field>
        <v-text-field
          :counter="20"
          label="Hospital Phone Number"
          v-model="inpatientObj.hospitalNumber"
          required
          outlined
        ></v-text-field>
        <v-text-field
          type="number"
          label="Room Number"
          v-model="inpatientObj.roomNumber"
          required
          outlined
        ></v-text-field>
        <v-text-field
          :counter="50"
          label="Monitoring Type"
          v-model="inpatientObj.hospitalMonitoringType"
          required
          outlined
        ></v-text-field>
        <v-text-field
          :counter="50"
          label="Test Length"
          v-model="inpatientObj.testLength"
          required
          outlined
        ></v-text-field>
        <v-text-field
          :counter="50"
          label="EEG Tech"
          v-model="inpatientObj.hospitalEEGTech"
          required
          outlined
        ></v-text-field>
        <v-text-field
          :counter="50"
          label="Contact Nurse"
          v-model="inpatientObj.contactNurse"
          required
          outlined
        ></v-text-field>
        <v-text-field
          :counter="50"
          label="Neurologist"
          v-model="inpatientObj.neurologist"
          required
          outlined
        ></v-text-field>
        <v-textarea
          :counter="150"
          label="Test Duration Reasoning"
          v-model="inpatientObj.testDurationReasoning"
          required
          outlined
        ></v-textarea>
      </v-form>
    </template>
    <template v-slot:actions>
      <v-btn
        @click="submit"
        large
        color=""
        depressed
        class="v-btn--text primary--text"
        :disabled="!valid || loading"
        submit
        >submit</v-btn
      >
      <v-btn
        @click="clear"
        color=""
        depressed
        class="v-btn--text error--text"
        :disabled="loading"
        >clear</v-btn
      >
    </template>
  </BaseModal>
</template>

<script>
import services from "@/services";
// import moment from "moment";

export default {
  $_veeValidate: {
    validator: "new",
  },
  props: {
    createDialog: {
      type: Boolean,
      default: false,
    },
    patientId: {
      type: Number,
      default: 0,
    },
  },
  name: "session-add",
  data: () => ({
    currentDate: new Date().toString(),
    loading: false,
    show1: false,
    valid: false,
    phoneMask: [
      "(",
      /\d/,
      /\d/,
      /\d/,
      ") ",
      /\d/,
      /\d/,
      /\d/,
      "-",
      /\d/,
      /\d/,
      /\d/,
      /\d/,
    ],
    inpatientObj: {},
    statusList: [],
    dictionary: {
      attributes: {
        email: "E-mail Address",
        // custom attributes
      },
      custom: {
        // name: {
        //   required: () => "Name can not be empty",
        //   max: "The name field may not be greater than 10 characters",
        //   // custom messages
        // },
        // select: {
        //   required: () => "Select field is required",
        // },
      },
    },
  }),
  computed: {
    availableRoles() {
      return this.$store.state.admin.availableRoles;
    },
  },
  methods: {
    submit() {
      this.$validator.validateAll();
      this.loading = true;
      this.inpatientObj.patientId = this.$props.patientId;

      services.inpatientService
        .create(this.inpatientObj)
        .then((response) => {
          if (response) {
            this.$store.commit(
              "snack/set_snack",
              "inpatient session was successfully created."
            );

            this.$emit("sessionWasCreated");
            return this.clear();
          } else {
            return this.$store.commit(
              "snack/set_snack",
              "Failed to create inpatient session. Please try again."
            );
          }
        })
        // eslint-disable-next-line no-unused-vars
        .catch((error) => {
          return this.$store.commit(
            "snack/set_snack",
            "Failed to create inpatient session. Please try again."
          );
        })
        .finally(() => (this.loading = false));
    },
    async getSessionStatuses() {
      this.statusLoader = true;

      return await services.patientService
        .getSessionStatuses()
        .then((response) => {
          this.statusList = response;
        })
        .finally(() => (this.statusLoader = false));
    },
    clear() {
      this.inpatientObj = {};
      this.$validator.reset();
    },
  },
  mounted: function() {
    this.$validator.localize("en", this.dictionary);
    this.getSessionStatuses();
    this.clear();
  },
};
</script>

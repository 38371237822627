<template>
  <section id="inpatients" >
    <v-toolbar flat style="border-bottom: 1px solid #c0c0c0">
      <h2>Inpatient History</h2>
      <v-spacer />
      <v-btn color="primary" @click="createDialog = true" v-if="isAdminUser">
        Create Inpatient
      </v-btn>
    </v-toolbar>
    <v-data-table
      :headers="headers"
      :items="items"                  
      :loading="loading"
      :single-expand="true"
      :expanded.sync="expanded"
      :search="searchText"
      show-expand
      @click:row="rowClick"
      item-key="inpatientSessionId"
      style="min-height=300px !important"
    >
      <template v-slot:expanded-item="{ headers, item }">
        <td
          :colspan="headers.length"
          style="background-color: #eef5f9"
          class="pa-1 pt-2 elevation-0"
        >
          <v-expand-transition>
            <inpatient
              :propInpatientSessionId="item.inpatientSessionId"
              :propInpatientSession="item"
              :key="item.inpatientSessionId"
              @expansionUpdate="getInpatientSessions()"
            />
          </v-expand-transition>
        </td>
      </template>
    </v-data-table>
    <inpatientAdd
      :createDialog="createDialog"
      :patientId="patientId"
      v-on:cancel="createDialog = false"
      v-on:sessionWasCreated="
       expanded = [];
        getInpatientSessions();
        createDialog = false;
      "
    />
  </section>
</template>

<script>
import { mapGetters } from "vuex";
import inpatient from "./index";
import inpatientAdd from "./inpatientAdd";

export default {
  name: "impatient-session",
  components: {
    inpatient,
    inpatientAdd,
  },
  props: {
    patientId: {
      type: Number,
      default: 0,
    },
  },
  data: () => ({
    inpatientsObj: [],
    createDialog: false,
    loading: false,
    search: "",
    timer: null,
    selected: null,
    expanded: [],
    searchText: "",
    totalCount: 0,
    items: [],
    options: {
      groupBy: [],
      groupDesc: [],
      itemsPerPage: 10,
      multiSort: false,
      mustSort: false,
      page: 1,
      sortBy: ["inpatientSessionId"],
      sortDesc: [true],
    },
    headers: [
      {
        text: "Session #",
        align: "start",
        sortable: false,
        value: "inpatientSessionId",
      },
      {
        text: "Patient #",
        align: "start",
        value: "patientId",
      },
      {
        text: "Hospital",
        align: "start",
        value: "hospital",
      },
      {
        text: "Hospital #",
        value: "hospitalNumber",
      },
      {
        text: "Room #",
        value: "roomNumber",
      },
      {
        text: "Monitor Type",
        value: "hospitalMonitoringType",
      },
      {
        text: "Test Length",
        value: "testLength",
      },
      {
        text: "EEG Tech",
        value: "hospitalEEGTech",
      },
      {
        text: "Contact Nurse",
        value: "contactNurse",
      },
      {
        text: "Neurologist",
        value: "neurologist",
      },
      {
        text: "Duration Reason",
        value: "testDurationReasoning",
      },
      { text: "", value: "data-table-expand" },
    ],
    page: {
      title: "Patients",
      icon: "mdi-patient",
    },
    breadcrumbs: [
      {
        text: "Patients",
        disabled: true,
      },
    ],
  }),
  watch: {
    patients(update) {
      this.inpatientsObj = update;
    },
     inpatientsObj(item) {
        var that = this;
      setTimeout(function() {
      that.expanded.push(item[0]);
      },300)
    },
  },
  computed: {
    ...mapGetters("patient", {
      patients: "inpatientSessions",
    }),
     isAdminUser() {
      return this.$store.state.user.isAdmin;
      },
     isTech() {
          return this.$store.state.user.isTech;
    },
  },

  methods: {
    async getInpatientSessions() {
      this.loading = true;
      let that = this;
      this.$store
        .dispatch("patient/get_inpatient_sessions", {
          options: that.options,
          searchText: that.searchText,
          patientId: that.$props.patientId,
        })
        .then((data) => {
          this.items = data.results;
          this.totalCount = data.totalCount;

           this.expanded.push(data.results[0]);
        })
        .finally(() => (this.loading = false));
    },
    rowClick: function(item, row) {
      row.select(true);
      this.selected = item;
    },
  },
  async mounted() {
    await this.getInpatientSessions();
  },
};
</script>

<template>
  <BaseModal
    :loading="loading"
    :dialog="deleteDialog"
    v-on:cancel="$emit('cancel')"
  >
    <template v-slot:title>
      Delete User
    </template>
    <template v-slot:body v-if="sessionDetail">
      Are you sure you want to delete the session detail for technician {{ sessionDetail.techFullName }}?
    </template>
    <template v-slot:actions>
      <v-btn
        @click="deleteDetail"
        large
        color=""
        depressed
        class="v-btn--text primary--text"
        :loading="loading"
        submit
        >submit</v-btn
      >
    </template>
  </BaseModal>
</template>

<script>
import services from "@/services";

export default {
  name: "detail-delete",
  props: {
    sessionDetail: {
      type: Object,
      default: null,
    },
    deleteDialog: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      loading: false,
    };
  },
  methods: {
    async deleteDetail() {
      this.loading = true;
      let that = this;
      await services.sessionDetailService
        .delete(this.$props.sessionDetail)
        .then(() => {
          that.$store.commit(
            "snack/set_snack",
            "Session detail was successfully deleted."
          );
          that.$emit("detailWasDeleted");
        })
        .finally(() => (this.loading = false));
    },
  },
};
</script>

<template>
  <section id="outpatient-session-detail">
    <v-card flat class="pt-0 mt-0 elevation-0">
      <v-card-title class="" style="border-bottom: 1px solid #c0c0c0">
        <h3>Shift</h3>
        <v-spacer></v-spacer>
        <v-btn color="primary" @click="createDialog = true" v-if="isAdminUser">
          Create
        </v-btn>
      </v-card-title>
      <v-row>
        <v-col lg="12">
          <v-card flat class="elevation-0">
            <v-card-text>
              <v-data-table
                :headers="headers"
                :items="sessionDetails"
                :items-per-page="100"
                :sort-by.sync="sortBy"
                :sort-desc.sync="sortDesc"
                hide-default-footer
                :loading="sessionDetailsLoader"
              >
                  <template v-slot:item.startTime="{ item }">
                      {{ formatDate(item.startTime) }}
                      {{ getTime(item.startTime) }}
                  </template>
                  <template v-slot:item.endTime="{ item }">
                      {{ formatDate(item.endTime) }}
                      {{ getTime(item.endTime) }}
                  </template>
                <template v-slot:item.techPhoneNumber="{ item }">
                  {{ normalizePhone(item.techPhoneNumber) }}
                </template>

               
                <template v-slot:item.actions="{ item }">
                  
                  <v-tooltip top>
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon
                        class="mr-2"
                        v-bind="attrs"
                        v-on="on"
                        :disabled="loading || !isAdminUser"
                        @click="deleteSessionDetail(item)"
                      >
                        mdi-delete
                      </v-icon>
                    </template>
                    <span>Delete Detail</span>
                  </v-tooltip>
                </template>
              </v-data-table>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-card>

    <!-- Modals -->
    <detailCreate
      :createDialog="createDialog"
      :outpatientSessionId="outpatientSessionId"
      :setupDate="outpatientSession.setupDate"
      :disconnectDate="outpatientSession.disconnectDate"
      v-on:cancel="createDialog = false"
      v-on:resetCreate="createKey = createKey + 1"
      :key="createKey"
      v-on:detailWasCreated="
        getSessionDetails();
        createDialog = false;
      "
    />
    <detailDelete
      :deleteDialog="deleteDialog"
      :sessionDetail="selectedSession"
      v-if="selectedSession"
      v-on:cancel="deleteDialog = false"
      v-on:detailWasDeleted="
        getSessionDetails();
        deleteDialog = false;
      "
    />
    <detailUpdate
      :updateDialog="updateDialog"
      :sessionDetail="selectedSession"
            :setupDate="outpatientSession.setupDate"
      :disconnectDate="outpatientSession.disconnectDate"
      v-if="selectedSession"
      v-on:cancel="updateDialog = false"
      v-on:detailWasUpdated="
        getSessionDetails();
        updateDialog = false;
      "
    />
  </section>
</template>

<script>
import { mapGetters } from "vuex";
import services from "@/services";

import detailCreate from "./detailCreate";
import detailDelete from "./detailDelete";
import detailUpdate from "./detailUpdate";
import moment from "moment";

export default {
  name: "session-detail-index",
  props: {
    outpatientSessionId: {
      type: Number,
      default: 0,
    },
  },
  components: {
    detailCreate,
    detailDelete,
    detailUpdate,
  },
  data: () => ({
    createKey: 1,
    deleteDialog: false,
    updateDialog: false,
      createDialog: false,
    sortBy: 'startTime',
    sortDesc: false,
    selectedSession: null,
    sessionDetails: [],
    sessionDetailsLoader: false,
    loading: false,
    headers: [
      {
        text: "Technician",
        align: "start",
        sortable: false,
        value: "techFullName",
      },
      {
        text: "Start Time",
        align: "start",
        sortable: true,
        value: "startTime",
      },
      {
        text: "End Time",
        align: "start",
        sortable: true,
        value: "endTime",
      },
      
      {
        text: "Email Address",
        align: "start",
        sortable: false,
        value: "techEmail",
      },
      {
        text: "Phone Number",
        align: "start",
        sortable: false,
        value: "techPhoneNumber",
      },

      {
        text: "Actions",
        align: "start",
        sortable: false,
        value: "actions",
      },
    ],
  }),
  watch: {
    // eslint-disable-next-line no-unused-vars
    sessionDetails(item) {
      // Convert technicians to a list of users/technicians.
      this.sessionDetailsLoader = true;

      // for each session detail, find the available technician and map it to the detail
      this.sessionDetails.forEach(function(detail) {
        if (!detail.technicianType) return;
        //detail.technicianType.description = detail.technicianType.description.replace(
        //  "Tech",
        //  ""
        //);
      });

      // assign our version of session detail to the component state sessionDetails.
      this.sessionDetailsLoader = false;
    },
  },
  computed: {
    ...mapGetters("session", {
      outpatientSession: "outpatientSession",
    }),
    isAdminUser() {
      return this.$store.state.user.isAdmin;
    }
  },
  methods: {
    async getSessionDetails() {
      this.sessionDetailsLoader = true;
      await services.sessionDetailService
        .getAll(this.$props.outpatientSessionId)
        .then((data) => {
          this.sessionDetails = data;
        })
        .finally(() => (this.sessionDetailsLoader = false));
      },
      formatDate(date) {
      if (!date) return "- -";
      var year = new Date(date).getFullYear();
      if (year === 1975 || year === '1975') {
        return "- -"
      }
      return moment.utc(date).tz('America/Chicago').format("YYYY-MM-DD");
      //return moment.tz(date, 'America/Chicago').format("YYYY-MM-DD HH:MM:SS");
    },
    async getOutpatientSession() {
      await this.$store.dispatch(
        "session/get_outpatient_session",
        this.$props.outpatientSessionId
      );
    },
    normalizePhone(phone) {
      //normalize string and remove all unnecessary characters
      phone = phone.replace(/[^\d]/g, "");

      //check if number length equals to 10
      if (phone.length == 10) {
        //reformat and return phone number
        return phone.replace(/(\d{3})(\d{3})(\d{4})/, "($1) $2-$3");
      }

      return null;
    },
    getTime(date) {
      return this.$moment(date).format("HH:mm a");
    },
    updateSessionDetail(detail) {
      this.selectedSession = detail;
      this.updateDialog = true;
    },
    deleteSessionDetail(detail) {
      this.selectedSession = detail;
      this.deleteDialog = true;
    },
    getAlertColor(item) {
      if (!item) return "secondary";

      var status = item.description.replace("Tech", "");

      switch (status) {
        case "Pickup":
          return "error";
        case "Maintenance":
          return "info";
        case "Setup":
          return "yellow";
        case "Reapplication":
          return "accent";
        case "Upload":
          return "success";
        default:
          return "secondary";
      }
    },
  },
  async mounted() {
    if (
      this.$props.outpatientSessionId &&
      this.$props.outpatientSessionId > 0
    ) {
      await this.getSessionDetails();
      await this.getOutpatientSession();
    }
  },
};
</script>

<template>
  <section id="outpatient-session-detail">
    <v-card class="pb-0 mb-0 elevation-0" flat >
      <v-form ref="form" v-model="valid" lazy-validation>
        <v-row>
          <v-col lg="12">
            <h2>Session Details</h2>
          </v-col>
        </v-row>
        <v-row>
          <v-col lg="4" xs="12">
            <!-- <v-date-picker
            v-model="outpatientObj.setupDate"
            label="Setup Date"/> -->

            <BaseDatePicker
              label="Setup Date"
              :date="outpatientObj.setupDate"
              :readonly="isReadOnly"              
              @change="outpatientObj.setupDate = $event"

            />
          </v-col>
          <v-col lg="4" xs="12">
            <BaseDatePicker
              label="Disconnect Date"
              :readonly="isReadOnly"
              :date="outpatientObj.disconnectDate"
              @change="outpatientObj.disconnectDate = $event"
            />
          </v-col>
          <v-col lg="4" xs="12">
            <v-text-field
              v-model="outpatientObj.testLength"
              label="Test Length"
              :disabled="isReadOnly"
              required
              outlined
              append-icon="mdi-timer"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col lg="4" xs="12">
            <v-select
              v-model="outpatientObj.sessionStatusId"
              :loading="statusLoader"
              :items="statusList"
              label="Session Status"
              item-text="description"
              item-value="lookSessionStatusId"
              :disabled="isReadOnly && !isTech"
              outlined
            >
            </v-select>
          </v-col>
          <v-col lg="4" xs="12">
            <v-text-field
              label="Unit Number"
              required
              outlined
              v-model="outpatientObj.unitId"
              :readonly="isReadOnly"
              :disabled="isReadOnly"
              append-icon="mdi-ballot"
            ></v-text-field>
          </v-col>
        </v-row>
         <v-row>
          <v-col lg="12" class="pb-3">
            <v-divider/>
          </v-col>
        </v-row>
         <v-row>
          <v-col lg="12">
            <h2>Physicians</h2>
          </v-col>
        </v-row>
        <v-row>
          <v-col lg="4" xs="12">
            <v-text-field
              :counter="100"
              label="Ordering Physician"
              v-model="outpatientObj.orderingPhysician"
              required
              :disabled="isReadOnly"
              outlined
              append-icon="mdi-doctor"
            ></v-text-field>
          </v-col>
          <v-col lg="4" xs="12">
            <v-text-field
              :counter="100"
              label="Reading Physician"
              v-model="outpatientObj.readingPhysician"
              required
              outlined
              :disabled="isReadOnly"
              append-icon="mdi-doctor"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col sm="12">
            <v-textarea
              :counter="150"
              v-model="outpatientObj.details"
              label="Details"
              required
              outlined
              :disabled="isReadOnly"
              append-icon="mdi-book"
              height="400px"
            ></v-textarea>
          </v-col>
        </v-row>
      </v-form>

      <v-card-actions>
        <v-spacer />
        <v-btn
          @click="update"
          large
          color
          depressed
          class="v-btn--text primary--text"
          :disabled="!valid || loading"
          submit
          >submit</v-btn
        >
        <v-btn
          @click="clear"
          color
          depressed
          class="v-btn--text error--text"
          :disabled="loading"
          >clear</v-btn
        >
      </v-card-actions>
    </v-card>
  </section>
</template>

<script>
import { mapGetters } from "vuex";
import services from "@/services";

export default {
  $_veeValidate: {
    validator: "new",
  },
  name: "outpatient-form",
  props: {
    outpatientSessionId: {
      type: Number,
      default: 0,
    },
    propOutpatientSession: {
      type: Object,
      default: null
    }
  },
  data: () => ({
    outpatientObj: {},
    createSessionDialog: false,
    statusLoader: false,
    loadingData: false,
    statusList: [],
    loading: false,
    page: {
      title: "outpatient Session",
      icon: "mdi-account-plus",
    },
    fullOrShortItem: ["Full", "Short"],
    valid: false,
    continuousItems: [
      {
        text: "Yes",
        value: true,
      },
      {
        text: "No",
        value: false,
      },
    ],
  }),
  watch: {
    outpatientSession(item) {
      this.outpatientObj = item;
    },
    propOutpatientSession(item) {
      if (item) {
        this.outpatientObj = item;
      }
    }
  },
  computed: {
    ...mapGetters("session", {
      outpatientSession: "outpatientSession",
    }),
      isReadOnly() {          
          return this.$store.state.user.isTech || this.$store.state.user.isReadOnly;
    },
      isTech() {
        return this.$store.state.user.isTech;
      },
  },
  methods: {
    async update() {
          this.loading = true;

      services.patientService
        .updateOutpatientSession(this.outpatientObj)
        .then((response) => {
            if (response) {
                console.log(response);
                if (response == 'Cannot activate session without unit assigned.') {
                    this.$store.commit(
                        "snack/set_snack",
                        response
                    );
                }
                else {
                    this.$store.commit(
                        "snack/set_snack",
                        "Outpatient Session Update Successful"
                    );
                }

            this.$emit('expansionUpdate')
          } else {
              
              console.log(response);
            this.$store.commit(
              "snack/set_snack",
              "Outpatient Session Update Failed"
            );
          }
        })
          .catch(() => {            
          this.$store.commit(
            "snack/set_snack",
            "Outpatient Session Update Failed."
          );
        })
        .finally(() => {
          this.loading = false;
        });
    },
    async getOutpatientSession() {
      if (this.$props.propOutpatientSession) {
        this.outpatientObj = this.$props.propOutpatientSession;
        return;
      }
      await this.$store.dispatch(
        "session/get_outpatient_session",
        this.$props.outpatientSessionId
      );
    },
    clear() {
      this.outpatientObj = this.outpatientSession;
    },
    async getSessionStatuses() {
      this.statusLoader = true;

      return await services.patientService
        .getSessionStatuses()
        .then((response) => {
          this.statusList = response;
        })
        .finally(() => (this.statusLoader = false));
    },
    normalizePhone(phone) {
      //normalize string and remove all unnecessary characters
      phone = phone.replace(/[^\d]/g, "");

      //check if number length equals to 10
      if (phone.length == 10) {
        //reformat and return phone number
        return phone.replace(/(\d{3})(\d{3})(\d{4})/, "($1) $2-$3");
      }

      return null;
      },
     
    getAlertColor(item) {
      if (!item) return "secondary";

      var status = item.description.replace("Tech", "");

      switch (status) {
        case "Pickup":
          return "error";
        case "Maintenance":
          return "info";
        case "Setup":
          return "yellow";
        case "Reapplication":
          return "accent";
        case "Upload":
          return "success";
        default:
          return "secondary";
      }
    },
  },
 
  async mounted() {
    if (this.outpatientSessionId && this.outpatientSessionId > 0) {
      await this.getOutpatientSession();
        await this.getSessionStatuses();

        console.log("Is ReadOnly");
        console.log(this.$store.state.user);
    }
  },
};
</script>

<template>
<section>
   <v-tabs v-model="activatedTab" color="info" >
      <v-tab v-for="item in items" :key="item.tab" class="text-capitalize font-weight-light subtitle-1">
          <v-icon class="d-block d-md-none">mdi-access-point</v-icon>
          <span class="d-none d-md-block">{{item.tab}}</span>
      </v-tab>
    </v-tabs>
    <v-tabs-items v-model="activatedTab" >
      <v-tab-item v-for="item in items" :key="item.tab" active-class="primary">
        <v-card flat>
          <v-card-text style="border-top: .1em solid #c0c0c0">
            <component :is="item.view" :outpatientSessionId="outpatientSessionId" :propOutpatientSession="propOutpatientSession" v-on:expansionUpdate="$emit('expansionUpdate')"></component>
          </v-card-text>
        </v-card>
      </v-tab-item>
    </v-tabs-items>
</section>
</template>

<script>
import { mapGetters } from "vuex";
import outpatient from "./outpatient.vue";
import sessionDetail from "../../components/sessionDetail/index"
export default {
  name: "outpatient-index",
  components: {
    outpatient,
    sessionDetail
  },
  props: {
    outpatientSessionId: {
      type: Number,
      default: 0
    },
    propOutpatientSession: {
      type: Object,
      default: null
    }
  },
  data: () => ({
    page: {
      title: "Outpatient Session",
      icon: "mdi-account-plus"
    },
    breadcrumbs: [],
    activatedTab: null,
    items: [
      { tab: "Session", view: "outpatient" },
      { tab: "Shift Schedule", view: "sessionDetail" },
    ],
  }),
  watch: {
    outpatientSession(item) {
      this.outpatientObj = item;
    },
  },
  computed: {
    // outpatientSessionId() {
    //   return parseInt(this.$route.params.outpatientSessionId, 0);
    // },
    patientId() {
      return parseInt(this.$route.params.id, 0);
    },
    ...mapGetters("patient", {
      patient: "patient"
    })
  },
  mounted() {
    if (this.patientId && this.patientId > 0) {
      this.$store.dispatch("patient/get_patient", this.patientId);
    }
  }
};
</script>

<template>
    <section>
        <v-card-title>
            <v-chip class="ma-2"                    
                    color="indigo darken-3"
                    outlined>
                <v-icon left>mdi-heart-pulse</v-icon>
                {{this.patient.fullName}}
            </v-chip>
           
        </v-card-title>
        <v-tabs v-model="activatedTab" color="info" background-color="transparent" v-if="isAdmin">
            <v-tab v-for="item in items"
                   :key="item.tab"
                   class="text-capitalize font-weight-light subtitle-1">
                <v-icon class="d-block d-md-none">mdi-access-point</v-icon>
                <span class="d-none d-md-block">{{ item.tab }}</span>
            </v-tab>
        </v-tabs>
        <v-tabs v-model="activatedTab" color="info" v-else>
            <v-tab v-for="item in techItems"
                   :key="item.tab"
                   class="text-capitalize font-weight-light subtitle-1">
                <v-icon class="d-block d-md-none">mdi-access-point</v-icon>
                <span class="d-none d-md-block">{{ item.tab }}</span>
            </v-tab>
        </v-tabs>
        <v-tabs-items v-model="activatedTab" v-if="isAdmin">
            <v-tab-item v-for="item in items" :key="item.tab" active-class="primary">
                <v-card flat>
                    <v-card-text style="border-top: .1em solid #c0c0c0">
                        <component :is="item.view"
                                   :patientId="patientId"
                                   v-on:patientUpdated="updatePatient"
                                   :loading="loading"></component>
                    </v-card-text>
                </v-card>
            </v-tab-item>
        </v-tabs-items>
        <v-tabs-items v-model="activatedTab" v-else>
            <v-tab-item v-for="item in techItems" :key="item.tab" active-class="primary">
                <v-card flat>
                    <v-card-text style="border-top: .1em solid #c0c0c0">
                        <component :is="item.view"
                                   :patientId="patientId"
                                   v-on:patientUpdated="updatePatient"
                                   :outpatientSessionId="myOutpatientSessionId"
                                   :loading="loading"></component>

                    </v-card-text>
                </v-card>
            </v-tab-item>

        </v-tabs-items>

    </section>
</template>

<script>
import inpatients from "../inpatient/inpatients.vue";
import outpatients from "../outpatient/outpatients";
import outpatient from "../outpatient/outpatient";
import patient from "./patient.vue";
import patientlogs from "../patientlogs/patientLogs";
import { mapGetters } from "vuex";

export default {
  name: "session-index",
  components: {
    inpatients,
    outpatients,
    patient,
    patientlogs,
    outpatient
  },
  props: {
    myPatientId: {
      type: Number,
      default: null,
    },
    myOutpatientSessionId: {
      type: Number,
      default: null,
    }
  },
  data: () => ({
    tab: { tab: "Patient", view: "patient" },
    items: [
      { tab: "Patient", view: "patient" },
      { tab: "Outpatient", view: "outpatients" },
      { tab: "Inpatient", view: "inpatients" },
      { tab: "Logs", view: "patientlogs" },
    ],
    techItems: [
      { tab: "Patient", view: "patient" },
      { tab: "Outpatient", view: "outpatients" },
      { tab: "Inpatient", view: "inpatients" },
      { tab: "Logs", view: "patientlogs" },
    ],
    page: {
      title: "Patient",
      icon: "mdi-account-plus",
    },
    breadcrumbs: [],
    loading: false,
  }),
  computed: {
    patientId() {
      if (this.$props.myPatientId && this.$props.myPatientId > 0) {
        return parseInt(this.$props.myPatientId, 0);
      } else {
        return parseInt(this.$route.params.id, 0);
      }
    },
    activatedTab: {
      get() {
        return this.$store.state.patient.activeTab;
      },
      set(val) {
        this.$store.commit("patient/set_active_tab", val);
      },
    },
    ...mapGetters("patient", {
      patient: "patient",
    }),
            isAdmin() {
      return this.$store.state.user.isAdmin;
        }
  },
  methods: {
    updatePatient(patientObj) {
      this.loading = true;
      this.$store
        .dispatch("patient/update_patient", patientObj)
        .finally(() => (this.loading = false));
    },
  },
  beforeDestroy() {
    this.activatedTab = this.tab;
  },
  mounted() {},
};
</script>

/* eslint-disable vue/no-unused-vars */
<template>
  <section id="outpatient">
    <v-toolbar flat style="border-bottom: 1px solid #c0c0c0">
      <h2>Outpatient History</h2>
      <v-spacer />
      <v-btn color="primary" @click="createDialog = true" v-if="isAdminUser">
        Create Outpatient
      </v-btn>
    </v-toolbar>
    <v-row>
      <v-col lg="12"> </v-col>
    </v-row>
    <v-data-table
      :headers="headers"
      :items="outpatientObj"
      :search="searchText"
      :options.sync="options"
      :server-items-length="totalCount"
      :loading="loading"
      :single-expand="true"
      :expanded.sync="expanded"
      show-expand
      @click:row="rowClick"
      item-key="outpatientSessionId"
    >
      <template v-slot:item.setupDate="props">
        {{ formatDate(props.item.setupDate) }}
      </template>
      <template v-slot:item.disconnectDate="props">
        {{ formatDate(props.item.disconnectDate) }}
      </template>
      <template v-slot:item.status="props">
        <v-chip
          color="green"
          class="white--text"
          v-if="props.item.status == 'Active'"
        >
          {{ props.item.status }}
        </v-chip>
        <v-chip color="default" v-else>
          {{ props.item.status }}
        </v-chip>
      </template>
      <template v-slot:item.sessionStatusId="{ item }">
        <v-chip :color="getAlertColor(item.sessionStatus)" label>
          <span v-if="item.sessionStatus && item.sessionStatus.description">
            {{ item.sessionStatus.description }}
          </span>
          <span v-else>
            - -
          </span>
        </v-chip>
      </template>
      <template v-slot:expanded-item="{ headers, item }">
        <td
          :colspan="headers.length"
          style="background-color: #eef5f9"
          class="pa-1 pt-2 elevation-0"
        >
          <v-expand-transition>
            <outpatient
              :outpatientSessionId="item.outpatientSessionId"
              :key="item.outpatientSessionId"
              :propOutpatientSession="item"
              @expansionUpdate="getOutpatientSessions()"
            />
          </v-expand-transition>
        </td>
      </template>
    </v-data-table>
    <outpatientAdd
      :createDialog="createDialog"
      :patientId="patientId"
      v-on:cancel="createDialog = false"
      v-on:sessionWasCreated="resetExpanded();getOutpatientSessions();createDialog = false;"
    />
  </section>
</template>

<script>
import outpatient from "./index";
import outpatientAdd from "./outpatientAdd";
import moment from "moment";
export default {
  name: "outpatient-session",
  components: {
    outpatient,
    outpatientAdd,
  },
  props: {
    patientId: {
      type: Number,
      default: 0,
    },
  },
  data: () => ({
    createDialog: false,
    loading: false,
    search: "",
    timer: null,
    selected: null,
    expanded: [],
    outpatientObj: [],
    options: {
      groupBy: [],
      groupDesc: [],
      itemsPerPage: 10,
      multiSort: false,
      mustSort: false,
      page: 1,
      sortBy: ["setupDate"],
      sortDesc: [true],
    },
    searchText: "",
    totalCount: 0,
    items: [],
    headers: [
      {
        text: "Outpatient Session #",
        align: "start",
        sortable: false,
        value: "outpatientSessionId",
      },
      {
        text: "Unit #",
        align: "start",
        sortable: true,
        value: "unitId",
      },
      {
        text: "Setup Date",
        value: "setupDate",
      },
      {
        text: "Disconnect Date",
        value: "disconnectDate",
      },
      {
        text: "Test Length",
        value: "testLength",
      },
      {
        text: "Full/Short",
        value: "fullOrShort",
      },
      {
        text: "Session Status",
        align: "start",
        sortable: false,
        value: "sessionStatusId",
      },
      { text: "", value: "data-table-expand" },
    ],
    page: {
      title: "Patients",
      icon: "mdi-patient",
    },
    breadcrumbs: [
      {
        text: "Patients",
        disabled: true,
      },
    ],
  }),
  watch: {
    items(item) {
      this.outpatientObj = item;
    },
    outpatientObj(item) {
      var that = this;
      setTimeout(function() {
      that.expanded.push(item[0]);
      },300)
    },
    options: {
      async handler() {
        this.expanded = [];
        await this.getOutpatientSessions();
      },
      deep: true,
    },
    searchText: {
      async handler() {
        if (this.timer) {
          clearTimeout(this.timer);
          this.timer = null;
        }
        this.timer = setTimeout(async () => {
          await this.getOutpatientSessions();
        }, 1000);
      },
      deep: true,
    },
  },
  computed: {
        isAdminUser() {
      return this.$store.state.user.isAdmin;
    },
  },
  methods: {
    resetExpanded(){
      this.expanded = []
    },
    formatDate(date) {
      return moment(date).format("MM/DD/YYYY");
    },
    rowClick: function(item, row) {
      row.select(true);
      this.selected = item;
    },
    async getOutpatientSessions() {
      this.loading = true;
      let that = this;
      await this.$store
        .dispatch("patient/get_outpatient_sessions", {
          options: that.options,
          searchText: that.searchText,
          patientId: that.$props.patientId,
        })
        .then((data) => {
          this.items = data.results;
          this.totalCount = data.totalCount;
        })
        .finally(() => (this.loading = false));
    },
    getAlertColor(item) {
      if (!item) return "secondary";

      var status = item.description;

      switch (status) {
        case "Setup Pending":
          return "yellow";
        case "Setup Complete":
          return "yellow";
        case "Active":
          return "primary";
        case "Disconnect Pending":
          return "error";
        case "Disconnect Complete":
          return "error";
        case "Upload & Battery Change Pending":
          return "success";
        case "Upload & Battery Change Complete":
          return "success";
        default:
          return "secondary";
      }
    },
  },
  async mounted() {
    await this.getOutpatientSessions();
  },
};
</script>

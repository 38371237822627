<template>
    <BaseModal :loading="loading"
               :dialog="updateDialog"
               width="1080"
               v-on:cancel="$emit('cancel')"
               v-on:show="test = somenewvalue">
        <template v-slot:title>
            Update Patient Log
        </template>
        <template v-slot:body>
            <v-form ref="form" v-model="valid" lazy-validation>
                <v-row>
                    <v-col sm="12">
                        <v-select label="Monitor Type"
                                  v-model="selectedMonitorIdComputed"
                                  :items="monitorTypes"
                                  :loading="loadingMonitorTypes"
                                  item-text="name"
                                  item-value="monitorId"
                                  outlined />
                    </v-col>
                </v-row>
                <v-row>
                    <v-col sm="12">
                        <v-select label="Category"
                                  v-model="selectedCategoriesComputed"
                                  :items="categories"
                                  :loading="loadingCategories"
                                  return-object
                                  item-text="description"
                                  item-value="value"
                                  multiple
                                  outlined />
                    </v-col>
                </v-row>
                <v-row>
                    <v-col sm="12">
                        <v-textarea :counter="150"
                                    v-model="selectedMonitorLogComputed"
                                    label="Monitor Log"
                                    required
                                    outlined
                                    append-icon="mdi-book"
                                    height="400px"></v-textarea>
                    </v-col>

                </v-row>
            </v-form>
        </template>

        <template v-slot:actions>

            <v-btn @click="submit()"
                   large
                   color=""
                   depressed
                   class="v-btn--text primary--text"
                   :disabled="!valid || loading"
                   submit>Update</v-btn>
            <v-btn @click="clear"
                   color=""
                   depressed
                   class="v-btn--text error--text"
                   :disabled="loading">clear</v-btn>
            <v-btn @click="deletePatientLog"
                   align="left"
                   large
                   color=""
                   depressed
                   class="v-btn--text error--text"
                   submit>Delete</v-btn>
        </template>
        
        
    </BaseModal>
</template>

<script>
import services from "@/services";

export default {
  $_veeValidate: {
    validator: "new",
  },
  props: {
    updateDialog: {
      type: Boolean,
      default: false,
      },
    patientLogIdProp: {
      type: Number,
      default: 0,
      },  
    patientId: {
      type: Number,
      default: 0,
      },  
    monitorTypeId: {
      type: Number,
      default: 0,
      },  
    selectedCategoryTypesProp: {
      type: Array,
      default: 0,
      }, 
    categoryTypesProp:  {
      type: Array,
      default: null
      },
    clickedProp: {
        type: Boolean,
        default: false,
    },
    monitorTypesProp: {
      type: Array,
      default: null
      },
    monitorLogProp:  {
      type: "",
      default: null
    },
  },
  name: "createLog",
        data: () => ({
            loading: false,
      loadingMonitorType: true,
      loadingCategories: true,
      loadingMonitorLog: true,
    loadingCategories: false,
    loadingMonitorTypes: false,
    show1: false,
      valid: true,
      dialogVisible: false,
    patientLog: {
      patientId: 0,
      monitorId: 0,//this.monitorTypeId,
      categoryTypes: [],
      monitorLog: "",
      },

    open: false,
    selectedCategories:[],
    selectedMonitorLog: "",
      selectedMonitorId: 0,
    monitorTypes: [],
      selectedCategory: {},
      categories: [],
    dictionary: {
      attributes: {
        email: "E-mail Address",
        // custom attributes
      },
      custom: {
        // name: {
        //   required: () => "Name can not be empty",
        //   max: "The name field may not be greater than 10 characters",
        //   // custom messages
        // },
        // select: {
        //   required: () => "Select field is required",
        // },
      },
    },
  }),
  watch: {
      updateDialog() {
          this.selectedMonitorId = this.$props.monitorTypeId; 
          this.selectedCategories = this.$props.selectedCategoryTypesProp;
          this.selectedMonitorLog = this.$props.monitorLogProp;
         },     
      selectedCategoriesProp(items) {
      if (item) {
        this.selectedCategories = items;
      }
      },
      selectedMonitorProp(item) {
      if (item) {
        this.selectedMonitorId = item;
      }
    },
    categoryTypesProp(items) {
      if (items) {
        this.categories = items;
      }
    },
    monitorTypesProp(items) {
        if (items) {
        this.monitorTypes = items;
      }
    }
        },
  computed: {
      selectedCategoriesComputed: {
          get: function () {              
              return this.selectedCategories;
          },
          set: function (newValue) {
              this.selectedCategories = newValue;
          },
      },
      selectedMonitorIdComputed:  {
          get: function () {               
              return this.selectedMonitorId;
          },
          set: function (newValue) {
              this.selectedMonitorId = newValue;
          }
      },
      selectedMonitorLogComputed:  {
          get: function () {              
              return this.selectedMonitorLog;
          },
          set: function (newValue) {
              this.selectedMonitorLog = newValue;
          }
      }
  },
  methods: {
      submit() {
          this.patientLog.patientId = this.$props.patientId;
          this.patientLog.monitorTypeId = this.selectedMonitorId;
          this.patientLog.categoryTypes = this.selectedCategories;
          this.patientLog.monitorLog = this.selectedMonitorLog;
          this.patientLog.patientLogId = this.$props.patientLogIdProp;

      this.$validator.validateAll();
      this.loading = true;      
      services.patientlogService
        .update(this.patientLog)
        .then(response => {
          if (response) {
            this.$store.commit(
              "snack/set_snack",
              "Patient log successfully updated."
            );

            this.$emit("logWasUpdated");
            return this.clear();
          } else {
            return this.$store.commit(
              "snack/set_snack",
              "Failed to update patient log. Please try again."
            );
          }
        })
        // eslint-disable-next-line no-unused-vars
        .catch(() => {
          return this.$store.commit(
            "snack/set_snack",
            "Failed to update patient log. Please try again."
          );
        })
        .finally(() => (this.loading = false));
      },
      deletePatientLog() {
          this.patientLog.patientId = this.$props.patientId;
          this.patientLog.monitorTypeId = this.selectedMonitorId;
          this.patientLog.categoryTypes = this.selectedCategories;
          this.patientLog.monitorLog = this.selectedMonitorLog;
          this.patientLog.patientLogId = this.$props.patientLogIdProp;

          services.patientlogService
        .delete(this.patientLog)
        .then(response => {
          if (response) {
            this.$store.commit(
              "snack/set_snack",
              "Patient log successfully deleted."
            );

            this.$emit("logWasUpdated");
            return this.clear();
          } else {
            return this.$store.commit(
              "snack/set_snack",
              "Failed to deleted patient log. Please try again."
            );
          }
        })
        // eslint-disable-next-line no-unused-vars
        .catch(() => {
          return this.$store.commit(
            "snack/set_snack",
            "Failed to deleted patient log. Please try again."
          );
        })
        .finally(() => (this.loading = false));

      },
      click() {
          this.loadingMonitorType = false;
          this.loadingCategories = false;
          this.loadingMonitorLog = false;
      },
    async getCategories() {
      this.loadingCategories = true;

      await services.patientLogService
        .getCategories()
        .then((response) => {
          this.categories = response;
        })
        .finally(() => (this.loadingCategories = false));
    },
    async getMonitorTypes() {
      this.loadingMonitorTypes = true;

      await services.patientLogService
        .getMonitorTypes()
        .then((response) => {
          this.monitorTypes = response;
        })
        .finally(() => (this.loadingMonitorTypes = false));
    },
    clear() {
      this.patientLog = {};
      this.patientLog.patientId = 0;
      this.selectedMonitorId = 0;
      this.selectedCategories = 0;
      this.selectedMonitorLog = "";
      this.$validator.reset();
    },
  },
  async mounted() {
    if (!this.$props.categoryTypesProp) {
      await this.getCategories();
    }
    this.$validator.localize("en", this.dictionary);
        },
  created() {
        },
};
</script>

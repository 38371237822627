<template>
  <BaseCard heading="Inpatient Session">
    <v-row>
      <v-col sm="12" lg="4">
        <v-text-field
          :counter="50"
          label="Hospital"
                      :disabled="isReadOnly"
          v-model="inpatientObj.hospital"
          required
          outlined
        ></v-text-field>
      </v-col>
      <v-col sm="12" lg="4">
        <v-text-field
          :counter="20"
                      :disabled="isReadOnly"
          label="Hospital Phone Number"
          v-model="inpatientObj.hospitalNumber"
          required
          outlined
        ></v-text-field>
      </v-col>
      <v-col sm="12" lg="4">
        <v-text-field
          type="number"
                      :disabled="isReadOnly"
          label="Room Number"
          v-model="inpatientObj.roomNumber"
          required
          outlined
        ></v-text-field>
      </v-col>
    </v-row>
    <v-row>
      <v-col sm="12" lg="4">
        <v-text-field
          :counter="50"
                      :disabled="isReadOnly"
          label="Monitoring Type"
          v-model="inpatientObj.hospitalMonitoringType"
          required
          outlined
        ></v-text-field>
      </v-col>
      <v-col sm="12" lg="4">
        <v-text-field
          :counter="50"
                      :disabled="isReadOnly"
          label="Test Length"
          v-model="inpatientObj.testLength"
          required
          outlined
        ></v-text-field>
      </v-col>
      <v-col sm="12" lg="4">
        <v-text-field
          :counter="50"
                      :disabled="isReadOnly"
          label="EEG Tech"
          v-model="inpatientObj.hospitalEEGTech"
          required
          outlined
        ></v-text-field>
      </v-col>
    </v-row>
    <v-row>
      <v-col sm="12" lg="4">
        <v-text-field
          :counter="50"
                      :disabled="isReadOnly"
          label="Contact Nurse"
          v-model="inpatientObj.contactNurse"
          required
          outlined
        ></v-text-field>
      </v-col>
      <v-col sm="12" lg="4">
        <v-text-field
          :counter="50"
                      :disabled="isReadOnly"
          label="Neurologist"
          v-model="inpatientObj.neurologist"
          required
          outlined
        ></v-text-field>
      </v-col>
    </v-row>
    <v-row>
      <v-col sm="12">
        <v-textarea
          :counter="150"
                    :disabled="isReadOnly"
          label="Test Duration Reasoning"
          v-model="inpatientObj.testDurationReasoning"
          required
          outlined
        ></v-textarea>
      </v-col>
    </v-row>
    <template v-slot:actions>
      <v-btn
        @click="update"             
        large
        color=""
        depressed
        class="v-btn--text primary--text"
        :disabled="isReadOnly"
        submit
        >submit</v-btn
      >
      <v-btn
        @click="clear"
        color=""
        depressed
        class="v-btn--text error--text"
        :disabled="isReadOnly"
        >clear</v-btn
      >
    </template>
  </BaseCard>
</template>

<script>
import { mapGetters } from "vuex";
import services from "@/services";

export default {
  name: "inpatient",
  props: {
    inpatientSessionId: {
      type: Number,
      default: 0,
    },
    propInpatientSession: {
      type: Object,
      default: null
    }
  },
  data: () => ({
    inpatientObj: {},
    valid: false,
    loading: false,
    page: {
      title: "Inpatient Session",
      icon: "mdi-account-plus",
    },
  }),
  watch: {
    inpatientSession(item) {
      this.inpatientObj = item;
    },
    propInpatientSession(item) {
      this.inpatientObj = item;
    }
  },
  computed: {
    ...mapGetters("session", {
      inpatientSession: "inpatientSession",
    }),
      isReadOnly() {
          return this.$store.state.user.isTech || this.$store.state.user.isReadOnly;
    },
  },
  methods: {
    async update() {
      this.loading = true;
      await services.patientService
        .updateInpatientSession(this.inpatientObj)
        .then((response) => {
          if (response) {
             this.$emit("expansionUpdate");
            this.$store.commit(
              "snack/set_snack",
              "Intpatient Session Update Successful"
            );
          } else {
            this.$store.commit(
              "snack/set_snack",
              "Inpatient Session Update failed"
            );
          }
        })
        .catch(() => {
          this.$store.commit(
            "snack/set_snack",
            "Inpatient Session Update failed"
          );
        })
        .finally(() => (this.loading = false));
    },
    async get() {
      if (this.$props.propInpatientSession) {
        this.inpatientObj = this.$props.propInpatientSession;
      }
      await this.$store.dispatch(
        "session/get_inpatient_session",
        this.$props.inpatientSessionId
      );
    },
    clear() {
      this.inpatientObj = this.inpatientSession;
    },
  },
  async mounted() {
    await this.get();
  },
};
</script>
